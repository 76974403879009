import React from 'react'
import '../../assets/scss/main.scss'
import Helmet from 'react-helmet'
import content from '../../l10n/data'

import Footer from './Footer'

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 'is-loading'
    }
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children } = this.props

    return (
      <div className={`body ${this.state.loading}`}>
        <Helmet htmlAttributes={{ lang : content.lang }}>
            <title>{content.siteTitle}</title>
            <meta name="description" content={content.metaDescription} />
            <meta name="keywords" content={content.metaKeywords} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            <meta name="apple-mobile-web-app-title" content="Emoojle" />
            <meta name="google-site-verification" content="qLrcJAUGVJ0uuvgo-4rHtBVCv-xSU06CBDdoyRgIW8M" />
        </Helmet>
        <div id="wrapper">
          <div id="innerWrapper">
            {children}
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default Template
