import React from 'react'
import content from '../../l10n/data'

const Footer = props => (
  <footer id="footer">
    <p className="copyright">
      <ul className="icons">
          <li>
            <a href="https://twitter.com/emoojle" className="icon fa-twitter alt" target="_blank">
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/emoojle" className="icon fa-instagram alt" target="_blank">
              <span className="label">Instagram</span>
            </a>
          </li>
        </ul>
      &copy; Emoojle {new Date().getFullYear()}. {content.footerDesignBy}: <a href="https://html5up.net">HTML5 UP</a>.
    </p>
  </footer>
)

export default Footer
